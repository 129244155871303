import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Strategy`}</h1>
    <p>{`The Computational Democracy Project provides data science services in the form of `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` `}<a parentName="p" {...{
        "href": "/conversations",
        "title": "conversations"
      }}>{`conversations`}</a>{` and subsequent analysis to independent media outlets, academics, governments and non-profits.`}</p>
    <h3>{`Augmenting facilitators`}</h3>
    <p>{`Democratic governments are a form of collective intelligence. The means to acheiving intelligence in democratic governance structures is, uniformly, deliberation — a bringing together of diverse people and ideas to better understand an environment and steer via policy.`}</p>
    <p>{`The tools and methods CompDem brings to the global democracy ecosystem augment facilitators, who come in many forms. Sometimes these are newsrooms facilitating public conversation around news stories. Other times these are academics, facilitating sharing the views of the public more formally through research. Other times these facilitators are in government and need better ways to handle a flood of public comment, or activists organizing diverse coalitions and trying to figure out who speaks for a movement and what perspectives are on the periphery.`}</p>
    <p>{`By enabling earnest, public minded facilitators to create better portraits of the public in their own words, we seek to use technology for the public good and turn agenda setting power back to the public.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      